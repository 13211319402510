import DashboardService from 'common/services/resources/dashboard.js'
export default {
	props: ['selectedView'],
    data: function () {
        return {
			CONSTANTS:{
				RENAME_ERROR : this.$gettext('Unable to rename the view'),
				RENAME_SUCCESS : this.$gettext('The view has been successfully renamed')
			},
			name:'',
			loading : false
		}
    },
    created: function () {
		this.title= this.$gettextInterpolate(this.$gettext('Rename the view \'%{s}\''), {s: this.selectedView.name});
		this.name = this.selectedView.name;
    },
	methods: {
		renameView : function()
		{
			var _this = this;
			this.loading = true;
			DashboardService.editView(this.selectedView.id,{name:this.name}).then(
				function(response){
					_this.loading = false;
					_this.selectedView.name = _this.name;
					_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.RENAME_SUCCESS, 'success');
					_this.eventHub.$emit('renameViewFinished');
				}).catch(
					function(e) {
						_this.loading = false;
						if(e.response.status == 401)
							_this.eventHub.$emit('logout');
						else
							_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.RENAME_ERROR);
				});
		}
	}
}
