import DashboardService from 'common/services/resources/dashboard.js'

import DeleteWidget from '../delete/delete.vue'
import SetBackground from '../set-background/set-background.vue'
import SetFontColor from '../set-font-color/set-font-color.vue'

export default {
    props: ['selectedView','widget'],
    components: {
        DeleteWidget,
        SetBackground,
        SetFontColor
      },    
    data: function () {
        return {
			CONSTANTS:{
                SETTINGS : 
                [
                    {
                        icon: 'trash',
                        title: 'Delete the widget',
                        settings : 'delete'
                    },
                    {
                        icon: 'image',
                        title: 'Modify the background',
                        settings : 'background'
                    }
                ]
            },
            settings: 'settings'    
		}
    },
    created: function () {
        if(this.widget.model.name != 'generic_push_to_talk'){
            var obj = {
                icon: 'colours',
                title: 'Modify the text color',
                settings : 'colorText'
            };
            this.CONSTANTS.SETTINGS.push(obj);
        }
    },
	methods: {
        selectSettings : function(settings){
            this.settings = settings;
        }
	}
}
