import DashboardService from 'common/services/resources/dashboard.js'

export default {
	props: ['scenarios'],	
	data:
		function () {
			return {
			}
		},
	created: function () {
	},
	methods: {
  }
}
