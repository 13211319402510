import { render, staticRenderFns } from "./add-text.vue?vue&type=template&id=d2be1200&scoped=true&"
import script from "./add-text.js?vue&type=script&lang=js&"
export * from "./add-text.js?vue&type=script&lang=js&"
import style0 from "./add-text.css?vue&type=style&index=0&id=d2be1200&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2be1200",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCardActions,VCardText,VFlex,VLayout,VTextField})
