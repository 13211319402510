import DashboardService from 'common/services/resources/dashboard.js'
import ColorService from 'common/services/color.js'

import { Chrome } from 'vue-color'

export default {
	props: ['selectedView','widget'],
	components:
	{
		'chrome-picker': Chrome,
	},	
    data: function () {
        return {
			CONSTANTS:{
				SET_FONT_COLOR_ERROR : this.$gettext('Unable to set the font color'),
				SET_FONT_COLOR_SUCCESS : this.$gettext('The font color has been successfully changed')
			},
			colors:'',
			loading : false	
		}
    },
    created: function () {
		var fontColor = this.widget.fontColor;
		this.colors = 
		{
			hex : ColorService.rgbToHex(fontColor.red,fontColor.green,fontColor.blue),
			rgba : {
				r:fontColor.red,
				g:fontColor.green,
				b:fontColor.blue,
				a:fontColor.alpha
			},
			a : fontColor.alpha
		}
    },
	methods: {
		setFontColor : function()
		{
			var _this = this;
			var rgba = this.colors.rgba;
			var color = {
				'fontColor': 
				{
					'red':rgba.r,
					'green':rgba.g,
					'blue':rgba.b,
					'alpha': rgba.a
				}
			};
			this.loading=true;
			DashboardService.editWidget(this.selectedView.id,this.widget.id,color).then(
				function(response){
					_this.loading=false;
					_this.widget.fontColor = color.fontColor;
					_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.SET_FONT_COLOR_SUCCESS, 'success');
					_this.eventHub.$emit('setWidgetFinished');
				}).catch(
					function(e) {
						_this.loading=false;
						if(e.response.status == 401)
							_this.eventHub.$emit('logout');
						else
							_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.SET_FONT_COLOR_ERROR);
				});
		}
	}
}
