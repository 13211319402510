import DashboardService from 'common/services/resources/dashboard.js'
import DeleteModal from 'common/components/delete-modal/delete-modal.vue'
export default {
	props: ['selectedView','widget'],
	components:{DeleteModal},
    data: function () {
        return {
			CONSTANTS:{
				DELETE_ERROR : this.$gettext('Unable to delete the widget'),
				DELETE_SUCCESS : this.$gettext('The widget has been successfully deleted')
			}	
		}
    },
    created: function () {
			var label = ''
			if(this.widget.model.category != 'GENERIC'){
				label = this.widget.label;
			}
			else{
				label = this.widget.model.displayName;
			}
			this.title= this.$gettextInterpolate(this.$gettext('Delete the widget \'%{s}\'?'), {s: this.$gettext(label)});
			this.content= this.$gettextInterpolate(this.$gettext('Are you sure you want to delete widget \'%{s}\'?'), {s: this.$gettext(label)});
    },
	methods: {
		deleteWidget : function()
		{
			var _this = this;
			DashboardService.deleteWidget(this.selectedView.id,this.widget.id).then(
				function(response){
					_this.eventHub.$emit('setLoading',false);
					_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.DELETE_SUCCESS, 'success');
					_this.eventHub.$emit('setWidgetFinished', 'delete');
				}).catch(
					function(e) {
						_this.eventHub.$emit('setLoading',false);
						if(e.response.status == 401)
							_this.eventHub.$emit('logout');
						else
							_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.DELETE_ERROR);
				});
		}
	}
}
