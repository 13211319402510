export default {
	props: ['widgetModels', 'rooms', 'scenarios', 'services', 'devices'],
	data: function () {
			return {
			}
		},
	created: function () {
	},
	methods: {
		modelsNotEmpty: function(key){
			switch(key){
				case 'GENERIC' :
					return true
					break;
				case 'DEVICE' :
					if(this.devices.length == 0)
						return false
					else
						return true
					break;
				case 'SCENARIO' :
					if(this.scenarios.length == 0)
						return false
					else
						return true
					break;
				case 'SERVICE' :
					if(this.services.length == 0)
						return false
					else
						return true
					break;
			}
		}
 	}
}
