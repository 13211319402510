import DashboardService from 'common/services/resources/dashboard.js'
import ColorService from 'common/services/color.js'

import { Chrome } from 'vue-color'

export default {
	props: ['selectedView','widget'],
	components:
	{
		'chrome-picker': Chrome,
	},
    data: function () {
        return {
			CONSTANTS:{
				SET_BACKGROUND_ERROR : this.$gettext('Unable to set the background'),
				SET_BACKGROUND_SUCCESS : this.$gettext('The background has been successfully changed')
			},
			colors:'',
			loading : false
		}
    },
    created: function () {
		var backgroundColor = this.widget.backgroundColor;
		this.colors = 
		{
			hex : ColorService.rgbToHex(backgroundColor.red,backgroundColor.green,backgroundColor.blue),
			rgba : {
				r:backgroundColor.red,
				g:backgroundColor.green,
				b:backgroundColor.blue,
				a:backgroundColor.alpha
			},
			a : backgroundColor.alpha
		}
    },
	methods: {
		setBackground: function()
		{
			var _this = this;
			var rgba = this.colors.rgba;
			var color = {
				'backgroundColor': 
				{
					'red':rgba.r,
					'green':rgba.g,
					'blue':rgba.b,
					'alpha': rgba.a
				}
			};
			this.loading=true;
			DashboardService.editWidget(this.selectedView.id,this.widget.id,color).then(
				function(response){
					_this.loading=false;
					_this.widget.backgroundColor = color.backgroundColor;
					_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.SET_BACKGROUND_SUCCESS, 'success');
					_this.eventHub.$emit('setWidgetFinished');
				}).catch(
					function(e) {
						_this.loading=false;
						if(e.response.status == 401)
							_this.eventHub.$emit('logout');
						else
							_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.SET_BACKGROUND_ERROR);
				});
		}
	}
}
