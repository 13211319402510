import CameraWidget from 'common/components/widget/control-device/camera/camera.vue'
import DevicesWidget from 'common/components/widget/widget.vue'
import GlobalSpinner from 'common/components/global-spinner/global-spinner.vue'
import StorageService from 'common/services/storage.js'

import AddView from './views/add/add.vue'
import RenameView from './views/rename/rename.vue'
import DeleteView from './views/delete/delete.vue'

import AddWidget from './widgets/add/add.vue'
import SetWidget from './widgets/set/set.vue'

export default {
  props: ['settings','plant', 'scenarios', 'templates', 'widget-models', 'dashboard', 'models', 'allTopologyFinished', 'services', 'devices', 'externalAccounts','brands', 'versionInfos','sharesLocations'],
  components: {
                CameraWidget,
                DevicesWidget,
                AddView,
                RenameView,
                DeleteView,
                AddWidget,
                SetWidget,
                GlobalSpinner
              },
   data:
		function () {
			return {
  			CONSTANTS:{
            EMPTY_WIDGET : {
                              backgroundColor: '',
                              data: {},
                              fontColor: '',
                              id: '',
                              index: 0,
                              label: '',
                              model: {}
                            },
            AVAILABLE_WIDGETS_MODELS : ['CAMERA', 'METEO', 'TV', 'ROLLING_SHUTTER', 'SCENARIO', 'GENERIC'],
            AVAILABLE_WIDGETS_MODELS_CAPABILITIES : ['humidity', 'position_vertical', 'protection', 'status', 'temperature']
        },
        hideTitle: false,

        swiper: {activeIndex:-1},
        swiperOption: {
            pagination: {
              el: '.swiper-pagination',
              clickable: true
            },
            mousewheel: true,
            autoplay : {delay : this.settings.viewAutoplayDelay*1000, disableOnInteraction:false}
        },
        controlBar: {
            playing: true
        },

        editMode : false,
        addViewDialog : false,
        renameViewDialog:false,
        deleteViewDialog : false,
        addWidgetDialog : false,
        setWidgetDialog: false,
        selectedView: null,
        editedCell: null,
        loading: true,
        hidePagination: false

    }
	},
  mounted: function () {
    this.eventHub.$emit('setHideRightPanel',true);
    
    this.eventHub.addEvent('launchModeEdit', this.launchModeEdit);
    this.eventHub.addEvent('addView', this.addView);
    this.eventHub.addEvent('addViewFinished', this.addViewFinished);
    this.eventHub.addEvent('renameView', this.renameView);
    this.eventHub.addEvent('renameViewFinished', this.renameViewFinished);
    this.eventHub.addEvent('deleteView', this.deleteView);
    this.eventHub.addEvent('deleteViewFinished', this.deleteViewFinished);
    this.eventHub.addEvent('addWidgetFinished', this.addWidgetFinished);
    this.eventHub.addEvent('setWidgetFinished', this.setWidgetFinished);
    this.eventHub.$on('launchEZFullscreenFinished', this.stopProcess);
    this.eventHub.$on('stopEZFullscreenFinished', this.startProcess);
    
    this.swiper = this.$refs['viewsSwiper'].$swiper;

    var _this = this;

    this.swiper.on('slideChange', function (swiper) {
      _this.slideChange(swiper);
      _this.eventHub.$emit('slideChange');
    });

    this.$nextTick(function() {
      var width = window.innerWidth;
      if(width<1025)
        {
          var squares = document.body.querySelectorAll('.square');
          for(var i = 0; i < squares.length ; i++)
          {
            squares[i].style.height = document.body.querySelector('.square').offsetWidth+'px';
          }
        }
    });
  },
  beforeDestroy: function ()
  {
    this.eventHub.$off('launchEZFullscreenFinished', this.stopProcess);
    this.eventHub.$off('stopEZFullscreenFinished', this.startProcess);
    //window.removeEventListener('orientationchange', this.doOnOrientationChange);
  },
  created: function () {
    var _this = this;
    //var viewAutoplayDelay = StorageService.getValue(localStorage, 'viewAutoplayDelay');

    //this.swiperOption.autoplay = {delay : this.settings.viewAutoplayDelay*1000, disableOnInteraction:false};
    this.checkHidePagination();
    //window.addEventListener('orientationchange', this.doOnOrientationChange);
    /*setTimeout(function() {
      _this.eventHub.$emit('changeOrientation', 'user');
    }, 2000);*/
  },
  watch: {

    'plant.id': function (val, oldVal) {
      this.$store.state.selectedPlantId = this.plant.id;
    },

    'dashboard.views': function (val, oldVal) {
      this.checkHidePagination();
    },
  },

  methods: {
    init: function()
    {
      this.loading=  false;
    },
    launchModeEdit : function()
    {
      this.editMode=true;
      this.pause();
    },
    backModeEdit : function()
    {
      this.play();
      this.editMode=false;
      this.eventHub.$emit('hideNavbar',false);
    },
    cellClassValue: function (cell) {
      var arrClass = {};
      arrClass['view-flex'] = true;
      //arrClass['w'+cell.width+'h'+cell.height] = true;
      //if(cell.widget && cell.widget.model.category=='SCENARIO')
        //arrClass['scenario'] = true;
      return arrClass;
    },
    cellStyleValue: function (cell,view) {
      var obj = { 
        top: cell.position.top, 
        left: cell.position.left,
        width: 'calc(100% * ('+cell.width+'/'+view.template.width+'))',
        height:'calc(100% * ('+cell.height+'/'+view.template.height+'))',
      }
      return obj;
    },
    widgetStyleValue: function (cell) {
      var obj = {};
      if(cell.widget)
      {
        obj.backgroundColor = 'rgba('+cell.widget.backgroundColor.red+', '+cell.widget.backgroundColor.green+', '+cell.widget.backgroundColor.blue+', '+cell.widget.backgroundColor.alpha+')';
        obj.color = 'rgba('+cell.widget.fontColor.red+', '+cell.widget.fontColor.green+', '+cell.widget.fontColor.blue+', '+cell.widget.fontColor.alpha+')';
      }
      return obj;
    },
    checkIfModelWidget: function (widget) {
      var isAvailableModel = (this.CONSTANTS.AVAILABLE_WIDGETS_MODELS.indexOf(widget.model.category)!=-1);
      if(isAvailableModel)
      {
        if(widget.model.capabilities && widget.model.capabilities.length > 0)
        {
          return (this.CONSTANTS.AVAILABLE_WIDGETS_MODELS_CAPABILITIES.indexOf(widget.model.capabilities[0])!=-1);
        }
        else
        {
          // For Generic text model widgetplay
          return (widget.model.name == 'Text');
        }
      }
      return false;
    },
    clickOnViewThumbnail: function (view, index) {
      this.dashboard.selectedView = view;
      this.swiper.slideTo(index);
    },
    slideChange: function () {
      var index = this.swiper.activeIndex;
      this.dashboard.selectedView = this.dashboard.views[index];
    },
    addView: function () {
      if(this.versionInfos.dashboardCount != 'INF' && this.dashboard.views.length >= this.versionInfos.dashboardCount){
        this.eventHub.$emit('displayAlertKey', 'unauthorized');
      }
      else{
        this.addViewDialog = true;
      } 
    },
    addViewFinished: function (view) {
      this.eventHub.$emit('initCells', view);
      this.dashboard.views.push(view);
      this.addViewDialog = false;
      var index = this.dashboard.views.length-1;
      var _this = this;
      setTimeout(function(){
        _this.swiper.slideTo(index);
                  },1
      );
      this.dashboard.selectedView = this.dashboard.views[index];
    },
    renameView: function () {
      this.renameViewDialog=true;
    },
    renameViewFinished: function () {
      this.renameViewDialog=false;
    },
    addWidget: function (index) {
      this.editedCell.widget = {};
      this.editedCell.widget.index = index;
      this.addWidgetDialog=true;
    },
    addWidgetFinished: function (widget) {
      this.editedCell.widget =  widget;
      this.addWidgetDialog=false;
    },
    setWidget: function () {
      this.setWidgetDialog=true;
    },
    setWidgetFinished: function (action) {
      switch(action)
      {
        case 'delete':
              this.editedCell.widget = null;
              break;
      }
      this.setWidgetDialog=false;
    },
    deleteView: function () {
      this.deleteViewDialog=true;
    },
    deleteViewFinished: function (bDelete) {
      if(bDelete)
      {
        this.dashboard.views.splice(this.swiper.activeIndex, 1);
      }
      var index = this.swiper.activeIndex;
      if(index!=0)
      {
        index = (index==this.dashboard.views.length?index-1:index);
        this.dashboard.selectedView = this.dashboard.views[index];
      }
      else
      {
        this.dashboard.selectedView = (this.dashboard.views.length>0?this.dashboard.views[0]:undefined);
      }
      this.deleteViewDialog=false;
    },
    editCell: function (cell, index) {
      if(!this.editMode)return false;
      this.editedCell = cell;
      if(cell.widget && cell.widget.model)
      {
        this.setWidget();
      }
      else
      {
        this.addWidget(index);
      }
    },
    managementMenu : function(){
      this.eventHub.$emit('showMenu');
    },
    doOnOrientationChange : function () {
      var orientation = window.orientation;
      if(orientation == 0)
        this.eventHub.$emit('goTo','rooms');
    },

    checkHidePagination : function ()
    {
      if(this.dashboard.views)
      {
        this.hidePagination = (this.dashboard.views.length<2);
      }
    },

    /*onClickView : function(id){
      this.eventHub.$emit('view-click',id);
    },*/

    startProcess: function()
    {
        this.play();
    },
    
    stopProcess: function()
    {
        this.pause();
    },

    /* CONTROL BAR */

    play: function()
    {
        this.swiper.autoplay.start();
        this.controlBar.playing = this.swiper.autoplay.running;
    },

    pause: function()
    {
      this.swiper.autoplay.stop();
      this.controlBar.playing = this.swiper.autoplay.running;
    
    },

    next: function()
    {
      var index = this.swiper.activeIndex;
      if(index == this.dashboard.views.length-1)
      {
        this.swiper.slideTo(0);
      }
      else 
      {
        this.swiper.slideNext()
      }
    },

    previous: function()
    {
      var index = this.swiper.activeIndex;
      if(index == 0)
      {
        this.swiper.slideTo(this.dashboard.views.length-1);
      }
      else
      {
        this.swiper.slidePrev()
      }
    }

 }
}
