import { render, staticRenderFns } from "./add3.vue?vue&type=template&id=6528eed2&scoped=true&"
import script from "./add3.js?vue&type=script&lang=js&"
export * from "./add3.js?vue&type=script&lang=js&"
import style0 from "./add3.css?vue&type=style&index=0&id=6528eed2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6528eed2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCardText,VDivider,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VProgressCircular})
