import { render, staticRenderFns } from "./view.vue?vue&type=template&id=10b477a9&scoped=true&"
import script from "./view.js?vue&type=script&lang=js&"
export * from "./view.js?vue&type=script&lang=js&"
import style0 from "./view.css?vue&type=style&index=0&id=10b477a9&scoped=true&lang=css&"
import style1 from "./view-vuetify.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10b477a9",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VDialog,VLayout,VList,VListItem,VListItemTitle,VMenu,VSpacer,VToolbar,VToolbarTitle})
