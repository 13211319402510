import DashboardService from 'common/services/resources/dashboard.js'

export default {
	data:
		function () {
			return {
				CONSTANTS:{
					VIEW_ERROR : this.$gettext('Unable to add the view'),
					VIEW_SUCCESS : this.$gettext('The view has been successfully added'),
					INPUT_ERROR : this.$gettext('Please enter a name for your view')
				},
				loading : false

			}
		},
	created: function () {
	},
	methods: {
		addView : function(){
			var _this = this;
			var eventHub = this.eventHub;
			if(this.$parent.view.name==''){
				_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.INPUT_ERROR);
			}
			else{
				this.loading = true;
				DashboardService.addView(_this.$parent.view.template.name, this.$parent.view.name).then(
				function(response){
					_this.loading = false;
					_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.VIEW_SUCCESS, 'success');
					_this.$parent.view.id = response.data.id;
					_this.eventHub.$emit('addViewFinished',  _this.$parent.view);
				}).catch(
					function(e) {
						_this.loading = false;
						if(e.response && e.response.status == 401)
							_this.eventHub.$emit('logout');
						else if(e.response && [402].indexOf(e.response.status)!=-1)
							_this.eventHub.$emit('displayAlertKey', 'unauthorized');
						else
							_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.VIEW_ERROR);
				});
			}
			//_this.eventHub.$emit('addView', _this.section.toUpperCase());
		}
  }
}
