import DeleteModal from 'common/components/delete-modal/delete-modal.vue'
import DashboardService from 'common/services/resources/dashboard.js'
export default {
	props: ['selectedView'],
	components:{DeleteModal},
    data: function () {
        return {
			CONSTANTS:{
				DELETE_ERROR : this.$gettext('Unable to delete the view'),
				DELETE_SUCCESS : this.$gettext('The view has been successfully deleted')
			}
		}
    },
    created: function () {
		this.title= this.$gettextInterpolate(this.$gettext('Delete the view \'%{s}\'?'), {s: this.selectedView.name});
		this.content= this.$gettextInterpolate(this.$gettext('Are you sure you want to delete view \'%{s}\'?'), {s: this.selectedView.name});
    },
	methods: {
		deleteView : function()
		{
			var _this = this;
			DashboardService.deleteView(this.selectedView.id).then(
				function(response){
					_this.eventHub.$emit('setLoading',false);
					_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.DELETE_SUCCESS, 'success');
					_this.eventHub.$emit('deleteViewFinished',true);
				}).catch(
					function(e) {
						if(e.response.status == 401)
							_this.eventHub.$emit('logout');
						else
							_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.DELETE_ERROR);
						_this.eventHub.$emit('setLoading',false);
				});
		}
	}
}
