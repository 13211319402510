export default {
	data:
		function () {
			return {
			}
		},
		computed: {
			filteredAvailableWidgetModels: function () {
				var _this = this;
				if(this.$parent.$parent.category=='DEVICE')
				{
					/*var model = (_this.$parent.$parent.module.device.subdeviceModels?_this.$parent.$parent.module.device.subdeviceModels[0].name:
									(_this.$parent.$parent.module.device.model=='rb'?'rb-relay':_this.$parent.$parent.module.device.model)
								);*/
					var model = (_this.$parent.$parent.module.device.model=='rb'?'rb-relay':_this.$parent.$parent.module.device.model);	
					//For Legrand, the model is plug but it's a subsocket
					if(model=='plug'){
						return this.$parent.$parent.availableWidgetModels.filter(function (wm) {
							return wm.entities.indexOf('subsocket') != -1;
						});
					}
					else{
						return this.$parent.$parent.availableWidgetModels.filter(function (wm) {
							return wm.entities.indexOf(model) != -1;
						});
					}
				}
				else if(this.$parent.$parent.category=='SERVICE'){
					return this.$parent.$parent.availableWidgetModels.filter(function (wm) {
						return wm.entities.indexOf(_this.$parent.$parent.module.service.model) != -1;
					});
				}
				else if(this.$parent.$parent.category=='GENERIC'){
					return _filter(this.$parent.$parent.availableWidgetModels, function(o) { return o.name == 'generic_text' || o.name == 'generic_push_to_talk'; });
				}
				else
				{
					return this.$parent.$parent.availableWidgetModels;
				}
			}
		},
		created: function () {
		},
		methods: {
			addWidget: function (wm) {
				this.$parent.$parent.widgetModel = wm;
				this.$parent.$parent.addWidget(wm);
			},
		}
}
