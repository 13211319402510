import DevicesList from 'common/components/devices-list/devices-list.vue'
import AddScenario from './add-scenario.vue'

export default {
	props: ['category','rooms','scenarios','availableWidgetModels'],
	components: {DevicesList, AddScenario},
	data:
		function () {
			return {
				verifyModels: [
								'sthi',
								'camera'
							]
			}
		},
	created: function () {
	},
	methods: {
		clickOnDevicesListItem: function(action,item)
		{
			this[action](item);
		},
		choose : function(element){
			this.$parent.$parent.chooseModule(element)
		}
  }
}
