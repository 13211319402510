import Add1 from './add1.vue';
import Add2 from './add2.vue';

export default {
	props: ['templates','views'],
	components: {Add1, Add2},
    data: function () {
        return {
			step: 1,
			view: {
				name:'page '+this.views.length,
				template:{}
			}
		}
    },
    created: function () {
		this.step = (this.$route.params.installStep?this.$route.params.installStep:1);
		this.eventHub.$on('nextStep', this.nextStep);
		this.eventHub.$on('backStep', this.backStep);
    },
	methods: {
		nextStep: function(){
			this.step=parseInt(this.step)+1;
		},
		backStep: function(){
			this.step=parseInt(this.step)-1;
		}
	}
}
