export default {
	data:
		function () {
			return {
				text:''
			}
		},
	created: function () {
	},
	methods: {
  }
}
