import DashboardService from 'common/services/resources/dashboard.js'
import Add1 from './add1.vue';
import Add2 from './add2.vue';
import Add3 from './add3.vue';
import Add4 from './add4.vue';

export default {
	props: ['dashboard','editedCell','widgetModels','rooms','plant','scenarios', 'services', 'devices', 'brands', 'models'],
	components: {Add1, Add2, Add3, Add4},
	data: function () {
		return {
			CONSTANTS:{
				WIDGET_ERROR : this.$gettext('Unable to add the widget'),
				WIDGET_SUCCESS : this.$gettext('The widget has been successfully added'),
				INPUT_ERROR : this.$gettext('Please enter a name for your widget'),
				MORE_DATAS_EDITORS : ['generic_text']
			},
			step: 1,
			module: null,
			availableWidgetModels : [],
			category:'',
			widget: null,
			widgetModel: null,
			loading : false
		}
	},
	created: function () {
		this.step = (this.$route.params.installStep?this.$route.params.installStep:1);
		this.eventHub.$on('nextStep', this.nextStep);
		this.eventHub.$on('backStep', this.backStep);
	},
	methods: {
		nextStep: function(){
			this.step=parseInt(this.step)+1;
		},
		backStep: function(){
			this.step=parseInt(this.step)-1;
		},
		chooseCategory : function(category, widgetModels){
			this.category= category;
			this.availableWidgetModels= widgetModels;
			this.step = (category != 'GENERIC'? 2:3);
		},
		chooseModule : function(module){
			this.module= module;
			this.editedCell.widget.data = {'moduleId':this.module.id};			
			this.nextStep();
		},
		addWidget : function(widgetModel){
			widgetModel.category = this.category;
			this.widgetModel = widgetModel;
			if(this.CONSTANTS.MORE_DATAS_EDITORS.indexOf(widgetModel.name)!=-1)
			{
				this.nextStep();
			}
			else
			{
				this.saveWidget();
			}
		},
		saveWidget : function(dataValue){
			var _this = this;
			this.loading = true;
			this.buildWidget(dataValue);
			DashboardService.addWidget(this.dashboard.selectedView.id, this.editedCell.widget).then(
				function(response){
					_this.widget = response.data;
					//Add manufacturer
					if(_this.widget.data && _this.widget.data.device)
					{
						var brand = _find(_this.brands, {'name': _this.widget.data.device.brand}) 
						if(brand)
						{
							_this.widget.data.device.brandObj = brand;
						}
						var model = _find(_this.models, {'name': _this.widget.data.device.model});
						if(model)
						{
							_this.widget.data.device.capabilities = model.capabilities;
						}
						if(_this.widget.data.device.relays || _this.widget.data.device.model == 'subsocket'){
							_this.widget.data.device.subdeviceModels = {};
						}
					}
					if(_this.widget.model.category == 'DEVICE' || _this.widget.model.category == 'SERVICE'){
						for(var i = 0; i < _this.plant.zones.length; i++){
							if(_this.plant.zones[i].modules){
								for(var j = 0; j< _this.plant.zones[i].modules.length; j++){
									if(_this.plant.zones[i].modules[j].id == _this.widget.data.id){
										_this.widget.data.zone = _this.plant.zones[i].name;
										break;
									}
								}
							}
						}
					}
					_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.WIDGET_SUCCESS, 'success');
					//_this.$parent.view.id = response.data.id;
					_this.eventHub.$emit('addWidgetFinished',_this.widget);
					_this.loading = false;
				}).catch(
					function(e) {
						_this.loading = false;
						/*if(e.response.status == 401)
							_this.eventHub.$emit('logout');
						else
							_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.WIDGET_ERROR);*/
				});
		},
		buildWidget : function(dataValue){
			if(dataValue)
				this.editedCell.widget.data = dataValue;
			this.editedCell.widget.widgetModel = this.widgetModel.name;

			/*this.editedCell.widget.data = {};
			if(this.widgetModel.category == 'DEVICE' || this.widgetModel.category == 'SCENARIO')
			{

				this.editedCell.widget.data = {'moduleId':this.module.id};
			}
			else if(this.widgetModel.name == 'Text')
			{
				this.editedCell.widget.data = {'text':dataValue}

			}
			else if(this.widgetModel.name == 'Go To View')
			{
				this.editedCell.widget.data = {'viewId': this.selectedView.id}
			}*/
		}
	}
}
