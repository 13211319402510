export default {
	props: ['templates'],
	data: function () {
			return {
				CONSTANTS:{
					DEFAULT_TEMPLATE_DISPLAY_NAME : this.$gettext('Default %{s}'),
					DEFAULT_TEMPLATE_DESCRIPTION : this.$gettext('%{s} cells of equal size'),
					FULLSCREEN_TEMPLATE_DISPLAY_NAME : this.$gettext('A full screen cell')
				}
			}
		},
	created: function () {
	},
	methods: {
		displayDefaultDisplayName : function(t){
			if (t.name.substring(0,8) == 'default_')
			{
				return this.$gettextInterpolate(this.CONSTANTS.DEFAULT_TEMPLATE_DISPLAY_NAME, {s: t.width+'x'+t.height});
			}
			else
			{
				return this.$gettext(t.displayName);
			}
		},
		displayDefaultDescription : function(t){
			if (t.description.indexOf('cells of equal size') != -1)
			{
				return this.$gettextInterpolate(this.CONSTANTS.DEFAULT_TEMPLATE_DESCRIPTION, {s: parseInt(t.width)*parseInt(t.height)});
			}
			else
			{
				return this.$gettext(t.description);
			}	
		},
		chooseTemplate : function(t){
			this.$parent.view.template = JSON.parse(JSON.stringify(t));
			this.eventHub.$emit('nextStep');
		}
 	}
}
