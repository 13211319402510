import AddText from './add-text.vue'

export default {
	components: {AddText},
	data:
		function () {
			return {
			}
		},		
		created: function () {
		},
		methods: {
		}
}
